/**
 * @file Manages the methods of the ActionScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import ActionTimeline from '../../components/home/action/ActionTimeline'
import AcceptedChallenges from '../../components/home/challenges/AcceptedChallenges'
import { domoscioAccess } from '../../api/domoscio_access'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { useScormUser } from '../../hooks/useScormUser'
/**
 * Renders the Action Screen
 *
 * @category Screens
 */
function ActionScreen() {
  const [width, setWindowWidth] = React.useState(0)
  const [acceptedChallenges, setAcceptedChallenges] = React.useState([])
  const [pendingReviews, setPendingReviews] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  // reviews scoped by experince for scorm users
  const scopedList = useScormUser()
  React.useEffect(() => {
    domoscioAccess.getPendingReviews(scopedList).then(reviews => {
      const filteredReviews = []
      const filteredChallenges = []
      // filter results to find action type and acccepted challenges
      reviews?.forEach(review => {
        if (review.content_type === 'ContentAction' && review.status === 'accepted') {
          filteredChallenges.push(review)
        } else {
          filteredReviews.push(review)
        }
      })
      setAcceptedChallenges(filteredChallenges)
      setPendingReviews(filteredReviews)
      setIsLoading(false)
    })

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const responsive = width < 1024
  const classes = useDomToolkit()
  return (
    !isLoading && (
      <div className={`ActionScreen ${classes.domMarginXLargeTop}`}>
        <ActionTimeline
          responsive={responsive}
          pendingReviews={pendingReviews}
          acceptedChallenges={acceptedChallenges}
          scopedList={scopedList}
        />
        {acceptedChallenges.length > 0 && <AcceptedChallenges challenges={acceptedChallenges} />}
      </div>
    )
  )
}

export default ActionScreen
