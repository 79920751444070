import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question, Loader } from '@domoscio/domoscio-ui'
import { useLearningSession, useNextQuestion } from '../../hooks/useLearningSession'
import { postEventReview } from '../../api/adaptive_engine/events'
import * as Errors from '../../components/Errors'
import '../../styles/LearningSession.scss'

const qti2js = (content: any) => Question.converter.qti2js((content as { qti: string }).qti)

/**
 * @category Screens
 * /learning_sessions
 */
const LearningSession = () => {
  // State initialization
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [processing, setProcessing] = useState<boolean>(false)
  const [results, setResults] = useState<any[]>([])

  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { reviews } = location.state

  const { learningSession, closeLearningSession } = useLearningSession()
  const { contents, isLoading, prepareNextContent, error } = useNextQuestion(reviews, activeIndex)

  // Constants
  const content = contents[activeIndex]
  const questionProps: any = content ? qti2js(content) : {}

  // Exit screen function
  const exit = () => {
    if (results.length === 0) {
      navigate('/')
    } else {
      navigate('/learning_sessions/result', { state: { results: results, length: reviews.length } })
    }
  }

  // <Question.onSubmit />
  const handleSubmit = async (answer: any) => {
    setProcessing(true)

    // Store results
    const result = { input: answer, content: content, props: questionProps }
    setResults([...results, result])

    // Preload next question
    prepareNextContent().then(() => setProcessing(false))

    // Post related event
    postEventReview({ ...result, learningSession: learningSession })
  }

  // <Question.onNextAction />
  const handleNextAction = async () => {
    const nextIndex = activeIndex + 1
    if (nextIndex === reviews.length) {
      closeLearningSession()
      exit()
    } else {
      setActiveIndex(activeIndex + 1)
    }
  }

  // Renderers
  return (
    <div className='LearningSession' data-index={activeIndex}>
      <Question.QuizHeader
        title={questionProps.concept}
        onCancel={exit}
        progress={{ value: activeIndex + 1, scale: reviews.length }}
      />
      {error ? (
        <div style={{ marginTop: '10%' }}>
          <Errors.ErrorScreen fill='gray' />
        </div>
      ) : (
        <section className='container'>
          {isLoading || learningSession === null ? (
            <Loader />
          ) : (
            <Question
              {...questionProps}
              heading={questionProps.concept}
              key={activeIndex}
              onSubmit={handleSubmit}
              onNextAction={handleNextAction}
              awaitingCallback={processing}
            />
          )}
        </section>
      )}
    </div>
  )
}

export default LearningSession
